import React from 'react';
import { setWebPageJsonLd, useSeo } from '@bamboo/core-lib/repo/seo';

type WebPageJsonLdProps = {
  id: string;
  type?: string;
  name: string;
  url: string;
  primaryImageOfPage: {
    '@id': string;
  };
  datePublished?: string;
  dateModified?: string;
  breadcrumb: {
    '@id': string;
  };
  potentialAction: {
    '@type': string;
    target: string[];
  }[];
  about?: {
    '@id': string;
  };
};

const WebPageJsonLd = (props: WebPageJsonLdProps) => {
  const {
    id,
    type = 'WebPage',
    name,
    url,
    about,
    primaryImageOfPage,
    datePublished = '',
    dateModified = '',
    potentialAction,
    breadcrumb,
  } = props;
  const { webPageJsonLd } = useSeo();

  const newWebPageJsonLd = {
    '@type': type,
    '@id': id,
    inLanguage: 'pt-BR',
    breadcrumb,
    url,
    name,
    primaryImageOfPage,
    datePublished,
    dateModified,
    potentialAction,
    about,
    isPartOf: {
      '@id': `${process.env.ECOMMERCE_URL}/#website`,
    },
  };

  React.useEffect(() => {
    if (webPageJsonLd === undefined) {
      setWebPageJsonLd(newWebPageJsonLd);
    }
  }, [webPageJsonLd]);

  if (typeof window === 'undefined') {
    setWebPageJsonLd(newWebPageJsonLd);
  }

  return null;
};

export default WebPageJsonLd;
